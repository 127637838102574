import React, { useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useScrollRestoration } from "gatsby"

import Layout from '../../components/template/Layout';
import { CurrentView } from '../../entities/templates';
import {
	CountryPagePropsType,
	lang,
	RawAccommodation,
	Region,
} from '../../entities/queriesTypes';
import useSetGlobalState from '../../components/hooks/useSetGlobalState';
import Wrapper from '../../components/template/ContainerMax';
import { useGlobalStateContext } from '../../Context/site';
import translations from '../../entities/translations';
import Text from '../../components/atoms/Text';
import PageSection from '../../components/organism/HomePageComponent';
import { Card } from '../../components/molecule/Card';
import { buildImageFocalPoint, formatMoney } from '../../utils';
import SelectButton from '../../components/atoms/SelectButton';
import { countrySubMenu } from '../../utils/subNavigation';
import PortableTextRenderer from '../../components/sanityBlockContents/PortableTextRenderer';
import { navigate } from 'gatsby';

type _Region = {
	title: Region['title'];
	_id: Region['_id'];
};

const IndexPage: React.FC<CountryPagePropsType> = (props) => {
	const { data, pageContext } = props;
	const page = data && data.pageData;
	const locale = pageContext && pageContext.locale;
  const ulScrollRestoration = useScrollRestoration(`region-component`)
	useSetGlobalState({
		page,
		pageContext,
		locale,
		currentView: CurrentView.country,
		subNavigationItem: countrySubMenu,
	});
	const { state } = useGlobalStateContext();
	const buttonTransaltion = translations.globals.buttons;
	const pageID = translations.globals.subMenuTranslation;
	const _accomodationTranslation = translations.CountryPages.find(
		(item) => item.menu === 'Unterkunfte'
	)!;
	const _Unterkunfte = translations.globals.subMenuTranslation.Unterkunfte;
	const [countryAccommodation, setCountryAccommodations] = useState<
		RawAccommodation[] | undefined
	>(undefined);
	const _regions = data.pageData.region as unknown as _Region[];
	const [searchRegionIds, setSetRegionIds] = useState<{[key: string]: string}>({});
	const pageHash = translations.countryMenuID;
	const AccommodationLinks =
		translations.globals.subMenuAccommodationTranslation;
	const FROM =
		translations.globals.subMenuTripTranslation.Ab?.[state?.lang as lang];

	const [regions, setRegions] = useState<null | _Region[]>(
		_regions as unknown as _Region[]
	);

	useEffect(() => {
		if (_regions) {
			const accomodationsEaw = page?.accommodation;
			// filter region that has ids in the accomodation records
			const filteredRegion: any = [];
			_regions.forEach((region) => {
				const regionId = region._id;
				const accomodation = accomodationsEaw?.find(
					(accomodation) => accomodation.parentRegion?._id === regionId
				);
				if (accomodation) {
					filteredRegion.push(region);
				}
			});

			setRegions(filteredRegion);
		}
	}, [_regions, page?.accommodation]);

	useEffect(() => {
		if (page?.accommodation?.length) {
			setCountryAccommodations(page?.accommodation);
		}
	}, [page?.accommodation]);

	const getRegionIdsFromFilterNames = useCallback((filterNames: string[]) => {
		const regionIds: any = {};
		filterNames.forEach((filterName) => {
			const region: any = regions?.find(
				(eachRegion) => eachRegion?.title && eachRegion?.title[state?.lang as lang] === filterName
			);
			if (region) {
				regionIds[region._id] = region._id;
			}
		});
		return regionIds;
	}, [regions, state?.lang]);

	const processRegionSetIds = useCallback(
		(arrayOfSearchIds: string[]) => {
			if (arrayOfSearchIds.length) {
				let _countryAccommodations: any = [];
				arrayOfSearchIds.forEach((_id) => {
					const _acc =
						[...page?.accommodation!]?.filter(
							(item) => item.parentRegion?._id === _id
						) || [];
					if (_acc.length) {
						_countryAccommodations = [..._countryAccommodations, ..._acc];
					}
				});
				setCountryAccommodations(_countryAccommodations);
			} else {
				setCountryAccommodations(page?.accommodation);
			}
		},
		[page?.accommodation],
	);
  const [filterNames, setFilterNames] = useState<string[]>([]);
	

	useEffect(() => {
		const query =  new URL(window.location.href).searchParams.get('filters')?.split?.(',')?.filter?.(ele => ele) || [];
		const removeDuplicates = [...new Set(query)];
		setFilterNames(removeDuplicates);
	}, [])

	useEffect(() => {
	if(searchRegionIds) {
		processRegionSetIds(Object.keys(searchRegionIds));
	}
	}, [searchRegionIds, processRegionSetIds])

	useEffect(() => {
		if(filterNames.length) {
			const regionIds = getRegionIdsFromFilterNames(filterNames);
			setSetRegionIds(regionIds);
		}
	}, [filterNames, getRegionIdsFromFilterNames])

	const handleSelect = (filterName: string = '') => {
		if (page?.accommodation && filterName) {
			const itExists = filterNames.find(item => item === filterName);
			let newFilterNames = [];
			if(!itExists){
				// add to filters list
				newFilterNames = [...filterNames, filterName];
			} else {
				// remove from filters list
				newFilterNames = filterNames.filter(item => item !== filterName);
			}
			setFilterNames(newFilterNames);
			// @ts-ignore
			const filterNameQuery = new URLSearchParams({ filters: newFilterNames }).toString();
			// create a new url with the new query
			const newUrl = `?${filterNameQuery}`;
			// redirect to the new url
			navigate(newUrl);	
		}
	};

	return (
		// @ts-ignore
		<div style={{ height: '100vh', overflow: `auto` }} {...ulScrollRestoration}>
		<Layout
			seoTitle={
				(page?.metadata?.page_title &&
					page?.metadata?.page_title[state?.lang as lang]) as string
			}
			meta={[
				{
					name: 'description',
					content: page?.metadata?.page_description
						? (page?.metadata?.page_description[state?.lang as lang] as string)
						: '',
				},
				{
					name: 'keywords',
					content: page?.metadata?.page_keywords
						? (page?.metadata?.page_keywords[state?.lang as lang] as string)
						: '',
				},
			]}
		>
			<Wrapper
				className="py-12"
				id={pageHash?.Unterkunfte?.[state?.lang as lang]!}
			>
				<div>
					<Text type="P" ptype="BASE" className="text-dove-gray font-medium">
						Filter {_Unterkunfte[state?.lang as lang]}:
					</Text>
					{regions
						?.sort(
							(a, b) =>
								a?.title?.[state?.lang as lang]?.localeCompare?.(
									b?.title?.[state?.lang as lang]!
								)!
						)
						?.map((item, i) => (
							<SelectButton
								onClick={() => {
									handleSelect(item?.title && item?.title[state?.lang as lang]);
								}}
								key={i}
								name={(item?.title && item?.title[state?.lang as lang]) || ''}
								isSelected={!!searchRegionIds[item._id!]}
							/>
						))}
				</div>

				<div className="mt-8">
					{page?.accomodationIntro?.[state?.lang as lang] && (
						<PortableTextRenderer
							body={page?.accomodationIntro?.[state?.lang as lang]}
						/>
					)}
				</div>
			</Wrapper>

			<PageSection
				readMoreLink={'#'}
				sanityContext={null}
				showReadMore={false}
				defaultPagePadding=""
				id={''}
				idTitle={pageID.Unterkunfte[state?.lang as lang]!}
				title={''}
			>
				<div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
					{countryAccommodation?.map((item, i: number) => (
						<Card
							uniqueRef={item?.['_id']!}
							key={i}
							_className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
							imageAlt={
								(item?.accommodationImage?.alt &&
									item?.accommodationImage.alt[state?.lang as lang]) ||
								''
							}
							imageUrl={buildImageFocalPoint(item.accommodationImage?.image)}
							title={(item?.title && item?.title[state?.lang as lang]) || ''}
							ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
							cardType="ACCOMODATION"
							activities={
								(item?.cardTextShort &&
									item.cardTextShort[state?.lang as lang]) ||
								''
							}
							price={
								`${FROM} ${formatMoney(
									item?.priceCategoryFrom || '',
									item.priceCategoryCurrency
								)}` || ''
							}
							showEuroIcon={false}
							ctaUrl={`/${state?.lang}/${
								item?.parentCountry?.slug &&
								item?.parentCountry?.slug[state?.lang as lang]?.current
							}/${_accomodationTranslation.slug[state?.lang as lang]}/${
								item?.slug && item?.slug[state?.lang as lang]?.current
							}#${AccommodationLinks.Lage[state?.lang as lang]}`}
							location={
								(item.locationNameShort &&
									item.locationNameShort[state?.lang as lang]) ||
								''
							}
							cardSize={'SMALL'}
						/>
					))}
				</div>
			</PageSection>
		</Layout>
		</div>
	);
};

export default IndexPage;

export const query = graphql`
	query countryAccomodationPage($id: String!) {
		pageData: sanityCountry(_id: { eq: $id }) {
			id: _id
			title: _rawTitle
			region: regions {
				title {
					de

				}
				_id
			}
			subTitle: _rawSubTitle(resolveReferences: { maxDepth: 10 })
			heroImage: _rawCountryAccommodationImage(
				resolveReferences: { maxDepth: 10 }
			)
			accomodationIntro: _rawAccommodationIntro(
				resolveReferences: { maxDepth: 10 }
			)
			metadata: _rawCountryAccommodationMetadata
			slug {
				de {
					current
				}
			}
			accommodation {
				_id
				accommodationImage {
					image: _rawImage(resolveReferences: { maxDepth: 10 })
					alt: _rawAlt(resolveReferences: { maxDepth: 10 })
				}
				slug {
					de {
						current
					}

				}
				cardTextShort {
					de

					_type
				}
				title {
					de

					_type
				}
				priceCategoryFrom
				priceCategoryTo
				rating
				priceCategoryCurrency
				parentRegion {
					_id
				}
				parentCountry {
					slug {
						de {
							current
							_type
						}
					}
				}
				locationNameShort {
					de

					_type
				}
				priceCategoryExpanded {
					_type
					de

				}
			}
		}
	}
`;
