import React, { useEffect, useState } from 'react';

interface IProp {
  name: string
  onClick: () => void;
  isSelected?: boolean
}
const SelectButton: React.FC<IProp> = ({ name, onClick, isSelected } ) => {
  const [selected, setSelected] = useState(isSelected);
  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected])
  return <p onClick={() => { setSelected(!selected); onClick()}}
          className={`${selected ? 'bg-fair-pink': ''} mb-3  md:mr-3 px-8 leading-none py-3 rounded-full text-sm text-granite-green focus:outline-none font-normal
   border-2 inline-block cursor-pointer border-granite-green relative`}>{name}
   <span className={`${selected ? '': 'hidden'} absolute -right-2 -top-2 rounded-full border border-granite-green text-center text-xs px-1 bg-white`}>x</span>
   </p>
}

export default SelectButton;
